import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './navigation.css'; // Assurez-vous que ce fichier contient les styles
import logo from './logo.png';

function Navigation({ isScrolled }) {
  const location = useLocation();
  const [isNavVisible, setNavVisible] = useState(false);

  const toggleNav = () => { 
    console.log("ok");
    setNavVisible(!isNavVisible); 
  };

  // Fermer la barre de navigation lorsque le chemin change
  useEffect(() => {
    setNavVisible(false);
  }, [location.pathname]);

  return (
    <div className="container">
      <button className="nav-toggle" onClick={toggleNav}>☰</button>

      {/* <header className={`navbar ${isNavVisible ? 'navbar-visible' : ''}`}> */}
      <header className={`navbar ${isScrolled ? 'navbar-colored' : 'navbar-opaque'}`}>
        <Link to="/home" className="navbar-logo">
          <img src={logo} alt="Logo" />
        </Link>
        <nav className={`nav ${isNavVisible ? 'nav-visible' : ''}`}>
          <ul className="nav-list">
            <li className="nav-item">
              <Link
                to="/home"
                className={`nav-link ${location.pathname === '/home' ? 'active' : ''}`}
                onClick={toggleNav}
              >
                Home page
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/projects"
                className={`nav-link ${location.pathname === '/projects' ? 'active' : ''}`}
                onClick={toggleNav}
              >
                Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/skills"
                className={`nav-link ${location.pathname === '/skills' ? 'active' : ''}`}
                onClick={toggleNav}
              >
                Skills
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/activities"
                className={`nav-link ${location.pathname === '/activities' ? 'active' : ''}`}
                onClick={toggleNav}
              >
                Activities
              </Link>
            </li>


            <li className="nav-item">
              <Link
                to="/contact"
                className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`}
                onClick={toggleNav}
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
}

export default Navigation;
