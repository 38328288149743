// import packages
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// import data, images and other things
import imageAbove from './bg.png';
import avatar from './profile2.png';

import './home2.css';

function Home({ onScroll }) {

  const [activeText, setActiveText] = useState(0);

  const [switchColor, changeColor] = useState('about');

  useEffect(() => {
    onScroll(false);
    const handleScroll = () => {
      if (window.scrollY > 150) {
        onScroll(true);
      } else {
        onScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const texts = [
    {
      title: 'About Me',
      content:
        "Hi, I'm Yousre Ouali, a passionate software developer dedicated to creating innovative and practical solutions. I graduated as an industrial software engineer in 2024 from HE2B ISIB in Belgium. During my studies, I completed two internships at Alstom and Irisib. These experiences equipped me with a robust skill set in collaborative and individual work environments.",
    },
    {
      title: 'Portfolio\'s Purpose',
      content:
        "This portfolio showcases my skills and projects dynamically and interactively. Unlike a static resume, it provides a platform to demonstrate my proficiency in web development and design. It's an evolving space where I share my latest projects, skills, and achievements, reflecting my commitment to learning and innovation.",
    },
  ];

  const handleDotClick = (index) => {
    setActiveText(index);
  };

  useEffect(() => {
    if (activeText === 0) {
      changeColor('about');
    } else {
      changeColor('portfolio');
    }
    window.scrollTo(0, 0);
  }, [activeText]);

  return (
    <div className='home-container'>
      <Helmet>
        <title>YousLab: Home page</title>
      </Helmet>

      {/* Image above the page */}
      <div className="image-container">
        <div className="color-bar"></div>
        <img src={imageAbove} alt="Background Above" className="responsive-image" />
        <div className="overlay-content">
          <img src={avatar} alt="Avatar" className="avatar-image" />
          <h3 className='hello-text'>Hello I'm</h3>
          <h1 className="welcome-text">Yousre Ouali</h1>
          <h2 className='introduction-text'>Software Developer | Problem Solver</h2>
        </div>
      </div>

      {/* Tag for about me and purpose of portfolio */}
      <div className={`text-switcher ${switchColor}`}>
        <div className="text-content">
          <h1>{texts[activeText].title}</h1>
          <p>{texts[activeText].content}</p>
        </div>
        <div className="dots-container">
          {texts.map((_, index) => (
            <span
              key={index}
              className={`dot ${activeText === index ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>

    </div>
  );
}

export default Home;
