import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { ThemeProvider } from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';


import './activities.css';

import hackathon1 from "./hackathon.png"
import hackathon2 from "./price.png"

import Game_Jam1 from "./Game_Jam.png"

import Private_Teacher from './private_teacher.png'

import Web_Dev from './web_dev.png'

import TTS from './TTS.png'

import IoT from './iot.png'

function Activities({ onScroll }) {
  useEffect(() => {
    onScroll(true);
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/activities/lesson"); // Navigate to the desired route
  };

  const activities = [
    { title: 'Participate in Hackathons', description: 'I joined a team to create an innovative project called Connecta, which was awarded the "Wallonie en Poche" Prize.', image: hackathon1, image2: hackathon2 },
    { title: 'Attend Game Jams', description: 'Participating in collaborative events like game jams, where teams create games within a short timeframe, has been an incredible learning experience for me. My first game jam took place in Brussels, where our team, consisting of one designer and four developers, collectively brainstormed and decided on the game\'s concept. We developed the game using Unity.', image: Game_Jam1 },
    { title: 'Private Lesson', description: 'Teaching mathematics and physics has been a fulfilling experience for me. During my studies, I completed the foundational courses in engineering. I enjoy teaching mathematics, as it not only allows me to share my knowledge but also helps me keep these concepts fresh and reinforces my understanding of them.', image: Private_Teacher, button: 'teaching' },
    { title: 'Web Development', description: 'Development of a website using React JS, deployed on OVH Cloud. This project involved extensive self-training on the React framework. The development was structured using an MVC architecture and incorporated tools like LottieFiles and Tailwind CSS for enhanced functionality and design.', image: Web_Dev },
    { title: 'Text-To-Speech', description: 'Development of a Text-to-Speech model based on WaveNet in Python, an advanced machine learning solution designed to generate high-quality audio directly from text input.', image: TTS },
    { title: 'Creating IoT Devices', description: 'I frequently design and develop connected devices using Arduino and ESP modules.', image: IoT },
  ];

  return (
    <div className="activities-container">
      <Helmet>
        <title>YousLab: My activities</title>
      </Helmet>
      <div className="activities-grid">
        {activities.map((activity, index) => (
          <div className="activity-card" key={index}>
            <img src={activity.image} alt={activity.title} className="activity-image" />
            <h2 className="activity-title">{activity.title}</h2>
            <p className="activity-description">{activity.description}</p>
            {activity.image2 && (
              <img
                src={activity.image2}
                alt={`${activity.title} additional`}
                className="activity-secondary-image"
              />
            )}



            {activity.button && (
              <Button
                style={{
                  backgroundColor: '#81B29A',
                  width: '100%',
                  height: '40px', // Let the height adjust dynamically
                  alignItems: 'center', // Vertically aligns items in the flex container
                  justifyContent: 'space-between', // Distributes text and icon
                  display: 'flex', // Makes the button a flex container
                  padding: '10px 15px', // Adds padding for better spacing
                }}
                onClick={handleNavigation}
              >
                <span style={{ fontSize: '14px', lineHeight: '1', whiteSpace: 'nowrap',maxWidth: '30%', color: 'black' }}>Read More</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="4 0 24 24"
                  strokeWidth={0.2}
                  stroke="currentColor"
                  style={{
                    flexShrink: 0,
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20 11.5L21 12m0 0l-1 0.5M21 12H18"
                  />
                </svg>
              </Button>
            )
            }



          </div>
        ))
        }


      </div>
    </div>
  );
}

export default Activities;
