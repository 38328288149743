import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { getProjects } from '../../model/projects/projects';
import './projects.css';

function Projects({onScroll}) {
  const [projects, setProjects] = useState([]);
  const [expandedProjects, setExpandedProjects] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');
  const projectRefs = useRef([]);

  useEffect(() => {
    onScroll(true);

    async function fetchProjects() {
      try {
        const data = await getProjects();
        setProjects(data.projects);
        setExpandedProjects(Array(data.projects.length).fill(false));
      } catch (error) {
        console.error('Error loading projects:', error);
      }
    }

    fetchProjects();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    projectRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      projectRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [projects]);

  const handleToggle = (index) => {
    setExpandedProjects((prevExpandedProjects) =>
      prevExpandedProjects.map((expanded, idx) =>
        idx === index ? !expanded : expanded
      )
    );
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const sortedProjects = [...projects].sort((a, b) => {
    return sortOrder === 'asc' ? a.year - b.year : b.year - a.year;
  });

  return (
    <div className="projects-container">
      <Helmet>
        <title>YousLab: My projects</title>
      </Helmet>

      {/* Sorting Selector */}
      <div className="sort-container">
        <label htmlFor="sortOrder">Sort by year: </label>
        <select id="sortOrder" value={sortOrder} onChange={handleSortChange}>
          <option value="desc">Newest First</option>
          <option value="asc">Oldest First</option>
        </select>
        <p className="info-text">Click on a project to see a brief summary.</p>
      </div>

      <ul className="project-list">
        {sortedProjects.map((project, index) => (
          <li
            key={index}
            className={`project-item ${expandedProjects[index] ? 'expanded' : ''}`}
            ref={(el) => (projectRefs.current[index] = el)}
            onClick={() => handleToggle(index)}
          >
            <div className="project-header">
              <h2 className="project-title">{project.name}</h2>
            </div>

            <div className="project-themes-year">
              <div className="project-themes">
                <h3 className="theme">Technology used:</h3>
                {project.theme_.map((theme, idx) => (
                  <h4 key={idx} className="theme-item">
                    {theme}
                  </h4>
                ))}
              </div>

              <div className="project-year">
                <h3 className="theme">Year: {project.year}</h3>
              </div>
            </div>

            {expandedProjects[index] && (
              <div className="project-abstract">
                {project.abstract.split('\n').map((paragraph, idx) => (
                  <p key={idx}>{paragraph}</p>
                ))}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Projects;
