import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './skills.css';

function Skills({onScroll}) {
  useEffect(() => {
    onScroll(true);
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="skills-container">
      <Helmet>
        <title>YousLab: My skills</title>
      </Helmet>

      <div className="skills-card soft-skills">
        <h2>Soft Skills</h2>
        <ul>
          <li><i className="fas fa-comments"></i> Communication</li>
          <li><i className="fas fa-users"></i> Teamwork</li>
          <li><i className="fas fa-user-check"></i> Self-reliant</li>
          <li><i className="fas fa-brain"></i> Problem-solving</li>
          <li><i className="fas fa-sync-alt"></i> Adaptability</li>
          <li><i className="fas fa-lightbulb"></i> Creativity</li>
        </ul>
      </div>

      <div className="skills-card hard-skills">
        <h2>Hard Skills</h2>
        <ul>
          <li><i className="fas fa-code"></i> Programming (Python, Java, C#, C)</li>
          <li><i className="fas fa-chart-bar"></i> Data Analysis</li>
          <li><i className="fas fa-laptop-code"></i> Web Development (HTML, CSS, JavaScript, React)</li>
          <li><i className="fas fa-database"></i> Database Management (SQL, NoSQL)</li>
          <li><i className="fas fa-brain"></i> Machine Learning</li>
          <li><i className="fas fa-git-alt"></i> Git</li>
          <li><i className="fas fa-tools"></i> Jenkins</li>
        </ul>
      </div>

      <div className="skills-card languages">
        <h2>Languages</h2>
        <ul>
        <li><i className="fas fa-globe"></i> French (Native)</li>
          <li><i className="fas fa-globe"></i> English (Intermediate)</li>
          <li><i className="fas fa-globe"></i> Dutch (Basic)</li>
          <li><i className="fas fa-globe"></i> Arabic (Basic)</li>
        </ul>
      </div>

      <div className="skills-card certifications">
        <h2>Certifications</h2>
        <ul>
          <li><i className="fas fa-certificate"></i> IT-oriented industrial engineering science</li>
        </ul>
      </div>
    </div>
  );
}

export default Skills;
